import { LOCALES } from '@/i18nConfig';

export const LOCALES_MAP: Record<(typeof LOCALES)[keyof typeof LOCALES], string> = {
  [LOCALES.EN_CA]: 'Canada (EN)',
  [LOCALES.EN_AU]: 'Australia (EN)',
  [LOCALES.EN_NZ]: 'New Zealand (EN)',
  [LOCALES.EN_HK]: 'Hong Kong (EN)',
  [LOCALES.FR_CA]: 'Canada (FR)',
};

export const COUNTRY_TO_LOCALE_MAP: Record<string, string> = {
  Canada: LOCALES.EN_CA,
  Australia: LOCALES.EN_AU,
  'New Zealand': LOCALES.EN_NZ,
  'Hong Kong': LOCALES.EN_HK,
};

export const LOCALE_TO_COUNTRY_MAP = Object.entries(COUNTRY_TO_LOCALE_MAP).reduce<
  Record<string, string>
>((acc, [key, value]) => {
  acc[value] = key;

  return acc;
}, {});
